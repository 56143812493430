<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="card__container">
      <div class="result__container success" v-if="info.orderStatus === '3'">
        <img src="../../assets/imgs/financial/result_success.png" />
        支付成功
      </div>
      <div class="result__container fail" v-if="info.orderStatus === '9'">
        <img src="../../assets/imgs/financial/result_fail.png" />
        支付失败
      </div>
      <div class="info__container">
        <div class="info__container__title">充值信息</div>
        <a-form class="advanced-search-form">
          <a-row>
            <a-col :span="12">
              <a-form-item label="交易方式">
                <span v-if="info.tradeType === 'wechat'">
                  微信支付
                </span>
                <span v-else-if="info.tradeType === 'ali'">
                  支付宝支付
                </span>
                <span v-else>
                  {{ info.bankName }}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="交易时间">
                {{ info.createTime ? tsFormat(info.createTime) : "-" }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="交易类型">
                <span v-if="info.dealType === 'recharge'">充值</span>
                <span v-else-if="info.dealType === 'deal'">交易</span>
                <span v-else>提现</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="付款方">
                {{ info.realName }} - {{ info.mobile }}
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="交易单号">
                {{ info.orderId }}
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="充值金额">¥{{ info.transAmt }}</a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="实付款">¥{{ info.realTransAmt }}</a-form-item>
            </a-col>
            <a-col :span="12" v-if="info.orderStatus === '9'">
              <a-form-item label="失败原因">
                {{ info.bankMessage || "-" }}
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="operation__container" v-if="info.orderStatus === '9'">
        <a-button class="btn--close" @click="onClose">关闭</a-button>
        <a-button type="primary" @click="onReload">重新充值</a-button>
      </div>
      <div class="operation__container" v-if="info.orderStatus === '3'">
        <a-button type="primary">关闭（{{ second }}s）</a-button>
      </div>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { checkRechargeDetail } from "@/services/PayService";
import { tsFormat } from "@/components/DateUtils";

export default {
  name: "RechargeResult",
  components: { Breadcrumb },
  data() {
    return {
      orderId: this.$route.params.orderId,
      isFactory: false,
      second: 5,
      info: {},
      tsFormat
    };
  },
  watch: {
    second: {
      handler(newVal) {
        if (newVal === 0) {
          if (this.isFactory) {
            this.$router.push({ name: "AccountBalanceFactory" });
          } else {
            this.$router.push({ name: "AccountBalanceOperation" });
          }
        }
      }
    }
  },
  mounted() {
    let roles = JSON.parse(localStorage.getItem("roles"));
    let index = roles.findIndex(item => item === "FactoryAdmin");
    if (index > -1) {
      this.isFactory = true;
    }
    this.loadDetail();
  },
  methods: {
    // 重新充值
    onReload() {
      this.$router.push({ name: "Recharge" });
    },
    // 关闭
    onClose() {
      if (this.isFactory) {
        this.$router.push({ name: "AccountBalanceFactory" });
      } else {
        this.$router.push({ name: "AccountBalanceOperation" });
      }
    },
    // 加载详情
    loadDetail() {
      checkRechargeDetail(this.orderId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          if (this.info.orderStatus === "3") {
            let timer = setInterval(() => {
              this.second--;
            }, 1000);
            this.$once("hook:beforeDestroy", () => {
              clearInterval(timer);
              timer = null;
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.card__container {
  min-height: 600px;
  box-sizing: border-box;
  padding: 0 50px;
}

.result__container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 0;
}

.result__container img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.success {
  color: rgba(67, 104, 246, 1);
  font-size: 28px;
}

.fail {
  color: rgba(255, 89, 106, 1);
  font-size: 28px;
}

.info__container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 18px;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.btn--close {
  margin-right: 20px;
}

.operation__container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 0;
}
</style>
