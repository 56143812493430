<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>收款银行卡管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container">
      <section class="table__container">
        <a-space>
          <a-button type="primary" icon="plus" @click="add">
            新建
          </a-button>
        </a-space>
        <a-table
          :columns="tblColumns"
          :data-source="tblData"
          :pagination="pagination"
          :loading="loading"
          class="data-table"
          rowKey="userId"
          @change="onTableChange"
        >
          <a-space slot="action" slot-scope="record">
            <a-popconfirm
              title="确认设为默认银行吗?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onSet(record)"
              v-if="!record.defaultFlag"
            >
              <a>设为默认银行</a>
            </a-popconfirm>
            <a @click="onEdit(record)">修改</a>
            <a-popconfirm
              title="确认删除角色吗?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </section>
    </div>
    <a-modal
      title="新增收款银行卡"
      width="1200px"
      :visible="addModel"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="addForm" labelAlign="left" class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              label="提现账户类型"
            >
              <a-select
                :disabled="isDetail"
                placeholder="请选择"
                v-decorator="[
                  'cardType',
                  { rules: [{ required: true, message: '请选择提现账户类型' }] }
                ]"
              >
                <a-select-option
                  v-for="item in accountTypeList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="银行卡号"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-input
                placeholder="请输入银行卡号"
                :disabled="isDetail"
                v-decorator="[
                  'accountNumber',
                  {
                    rules: [{ required: true, message: '请输入银行卡号' }]
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="开户银行名称"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-select
                :disabled="isDetail"
                v-decorator="[
                  'bankNo',
                  { rules: [{ required: true, message: '请选择开户银行' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="false"
                @search="getSearchBank"
                @change="changeBank"
              >
                <a-select-option v-for="item in backList" :key="item.bankNo">
                  {{ item.bankName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              label="开户银行所在"
            >
              <a-cascader
                :disabled="isDetail"
                v-decorator="[
                  'cityCode',
                  { rules: [{ required: true, message: '请选择开户银行所在' }] }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="支行名称"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-select
                :disabled="isDetail"
                v-decorator="[
                  'branchNo',
                  { rules: [{ required: true, message: '请选择支行名称' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="false"
                @search="getSearchSubbranch"
              >
                <a-select-option
                  v-for="item in backChildList"
                  :key="item.branchId"
                >
                  {{ item.branchName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" v-if="addForm.getFieldValue('cardType') === 'DC'">
            <a-form-item
              label="银行卡正面"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-upload
                :disabled="isDetail"
                v-decorator="[
                  'imgList',
                  { rules: [{ required: true, message: '请上传银行卡正面' }] }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="imgList"
                :before-upload="file => beforeUploadFile(file, 'imgList')"
                @preview="handlePreviewImage"
                @change="handleChangeImg"
              >
                <div v-if="imgList.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisible"
                @ok="previewVisible = false"
                @cancel="previewVisible = false"
              >
                <img
                  :src="previewImage"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="addForm.getFieldValue('cardType') === 'DC'">
            <a-form-item
              label="银行卡反面"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-upload
                :disabled="isDetail"
                v-decorator="[
                  'imgListBank',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请上传银行卡反面'
                      }
                    ]
                  }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="imgListBank"
                :before-upload="file => beforeUploadFile(file, 'imgListBank')"
                @preview="handlePreviewImageBank"
                @change="handleChangeImgBank"
              >
                <div v-if="imgListBank.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisibleBank"
                @ok="previewVisibleBank = false"
                @cancel="previewVisibleBank = false"
              >
                <img
                  :src="previewImageBank"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      title="修改收款银行卡"
      width="1200px"
      :visible="editModel"
      okText="确定"
      cancelText="取消"
      @ok="handleOkEdit"
      @cancel="handleCancelEdit"
    >
      <a-form :form="editForm" labelAlign="left" class="advanced-search-form">
        <a-row>
          <a-col :span="12">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              label="提现账户类型"
            >
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'cardType',
                  { rules: [{ required: true, message: '请选择提现账户类型' }] }
                ]"
              >
                <a-select-option
                  v-for="item in accountTypeList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="银行卡号"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-input
                disabled="disabled"
                placeholder="请输入银行卡号"
                v-decorator="[
                  'accountNumber',
                  {
                    rules: [{ required: true, message: '请输入银行卡号' }]
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="开户银行名称"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-select
                v-decorator="[
                  'bankNo',
                  { rules: [{ required: true, message: '请选择开户银行' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="false"
                @search="getSearchBank"
                @change="changeBank"
              >
                <a-select-option v-for="item in backList" :key="item.bankNo">
                  {{ item.bankName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              label="开户银行所在"
            >
              <a-cascader
                v-decorator="[
                  'cityCode',
                  { rules: [{ required: true, message: '请选择开户银行所在' }] }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="支行名称"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-select
                v-decorator="[
                  'branchNo',
                  { rules: [{ required: true, message: '请选择支行名称' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="false"
                @search="getSearchSubbranch"
              >
                <a-select-option
                  v-for="item in backChildList"
                  :key="item.branchId"
                >
                  {{ item.branchName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" v-if="editForm.getFieldValue('cardType') === 'DC'">
            <a-form-item
              label="银行卡正面"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-upload
                v-decorator="[
                  'imgList',
                  { rules: [{ required: true, message: '请上传银行卡正面' }] }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="imgList"
                :before-upload="file => beforeUploadFile(file, 'imgList')"
                @preview="handlePreviewImage"
                @change="handleChangeImg"
              >
                <div v-if="imgList.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisible"
                @ok="previewVisible = false"
                @cancel="previewVisible = false"
              >
                <img
                  :src="previewImage"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editForm.getFieldValue('cardType') === 'DC'">
            <a-form-item
              label="银行卡反面"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-upload
                v-decorator="[
                  'imgListBank',
                  { rules: [{ required: true, message: '请上传银行卡反面' }] }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="imgListBank"
                :before-upload="file => beforeUploadFile(file, 'imgListBank')"
                @preview="handlePreviewImageBank"
                @change="handleChangeImgBank"
              >
                <div v-if="imgListBank.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisibleBank"
                @ok="previewVisibleBank = false"
                @cancel="previewVisibleBank = false"
              >
                <img
                  :src="previewImageBank"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script>
import { imgUrl, upload } from "@/services/UploadService";
import {
  loadArea,
  searchBank,
  searchSubbranch
} from "@/services/DealerService";
import {
  bankcardAccountNumber,
  collectionCardAdd,
  collectionCardDel,
  collectionCardDetail,
  collectionCardList,
  collectionCardPut,
  collectionCardSetDefault
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";
import { onFetchCardNo } from "@/services/Archives";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const tblColumns = [
  {
    title: "开户机构/法人名称",
    dataIndex: "cardName",
    width: "25%"
  },
  {
    title: "开户银行名称",
    dataIndex: "bankName",
    width: "20%"
  },
  {
    title: "支行名称",
    dataIndex: "branchName",
    width: "25%"
  },
  {
    title: "银行卡号",
    dataIndex: "accountNumber",
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];
const accountTypeList = [
  {
    name: "对公账户（推荐）",
    id: "DCE"
  },
  {
    name: "对私法人账户",
    id: "DC"
  }
];
export default {
  name: "FactoryBankCard",
  data() {
    return {
      imgUrl,
      tblColumns,
      pagination: {
        showQuickJumper: true,
        pageNum: 1,
        pageSize: 10
      },
      tblData: [],
      total: 0,
      loading: false,
      addModel: false,
      addForm: this.$form.createForm(this),
      accountTypeList,
      backList: [],
      areaTreeData: [], // 行政区数据
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      backChildList: [],
      editModel: false,
      editForm: this.$form.createForm(this),
      bankNo: "",
      imgList: [],
      previewVisible: false,
      previewImage: "",
      imgListBank: [],
      previewVisibleBank: false,
      previewImageBank: "",
      bindId: "",
      isDetail: false
    };
  },
  mounted() {
    this.getList();
    this.onLoadArea();
  },
  methods: {
    add() {
      this.addModel = true;
      this.getSearchBank("");
      this.imgList = [];
      this.imgListBank = [];
      this.isDetail = false;
      this.$nextTick(() => {
        this.addForm.resetFields();
        this.addForm.setFieldsValue({
          cardType: "DCE"
        });
      });
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          resp.data.data.forEach(item => {
            item.subAreas.forEach(m => {
              this.$set(m, "subAreas", null);
            });
          });
          this.areaTreeData = resp.data.data;
        }
      });
    },
    //加载银行
    getSearchBank(val) {
      let params = {
        pageNum: 1,
        pageSize: 99,
        keyword: val
      };
      searchBank(params).then(resp => {
        this.backList = resp.data.data.records;
      });
    },
    changeBank(val) {
      this.bankNo = val;
      if (val) {
        this.getSearchSubbranch("");
      }
      this.addForm.setFieldsValue({
        branchNo: ""
      });
      this.editForm.setFieldsValue({
        branchNo: ""
      });
    },
    //加载支行
    getSearchSubbranch(val) {
      let params = {
        pageNum: 1,
        pageSize: 99,
        keyword: val,
        bankNo:
          this.bankNo.length === 8
            ? this.bankNo
            : this.bankNo.toString().padStart(8, "0")
      };
      searchSubbranch(params).then(resp => {
        this.backChildList = resp.data.data.records;
      });
    },
    getList() {
      collectionCardList({
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      }).then(resp => {
        this.tblData = resp.data.data.records;
      });
    },
    // 分页
    onTableChange(p) {
      this.pagination.pageNum = p.current;
      this.getList();
    },
    onEdit(item) {
      this.bindId = item.bindId;
      collectionCardDetail(item.bindId).then(resp => {
        this.editModel = true;
        const detailForm = resp.data.data;
        this.getSearchBank(resp.data.data.bankName);
        this.bankNo = resp.data.data.bankCode;
        if (this.bankNo) {
          this.getSearchSubbranch(resp.data.data.branchName);
        }

        if (detailForm.cardType === "DC") {
          this.imgList = [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: imageBaseUrl + detailForm.frontFile,
              fileUrl: detailForm.frontFile
            }
          ];
          this.imgListBank = [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: imageBaseUrl + detailForm.backFile,
              fileUrl: detailForm.backFile
            }
          ];
        }
        this.$nextTick(() => {
          this.editForm.setFieldsValue({
            accountNumber: detailForm.accountNumber,
            bankNo: detailForm.bankCode,
            branchNo: detailForm.branchCode,
            cardType: detailForm.cardType,
            cityCode: [detailForm.provinceCode, detailForm.cityCode]
          });
          setTimeout(() => {
            this.editForm.setFieldsValue({
              imgList: this.imgList,
              imgListBank: this.imgListBank
            });
          }, 100);
        });
      });
    },
    onDelete(item) {
      collectionCardDel(item.bindId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.pagination.pageNum = 1;
          this.getList();
        } else {
          this.$message.error(resp.data.errMsg);
        }
      });
    },
    //根据卡号获取银行卡
    getAccountNumber() {
      if (!this.addForm.getFieldValue("accountNumber")) {
        return;
      }
      bankcardAccountNumber(this.addForm.getFieldValue("accountNumber")).then(
        resp => {
          if (resp.data.code === "SUCCESS") {
            if (resp.data.data) {
              this.isDetail = true;
              const detailForm = resp.data.data;
              this.getSearchBank(resp.data.data.bankName);
              this.bankNo = resp.data.data.bankCode;
              this.getSearchSubbranch(resp.data.data.branchName);
              if (detailForm.cardType === "DC") {
                this.imgList = [
                  {
                    uid: "-1",
                    name: "image.png",
                    status: "done",
                    url: imageBaseUrl + detailForm.frontFile,
                    fileUrl: detailForm.frontFile
                  }
                ];
                this.imgListBank = [
                  {
                    uid: "-1",
                    name: "image.png",
                    status: "done",
                    url: imageBaseUrl + detailForm.backFile,
                    fileUrl: detailForm.backFile
                  }
                ];
              }
              this.$nextTick(() => {
                this.addForm.setFieldsValue({
                  accountNumber: detailForm.accountNumber,
                  bankNo: detailForm.bankCode,
                  branchNo: detailForm.branchCode,
                  cardType: detailForm.cardType,
                  cityCode: [detailForm.provinceCode, detailForm.cityCode]
                });
                setTimeout(() => {
                  this.addForm.setFieldsValue({
                    imgList: this.imgList,
                    imgListBank: this.imgListBank
                  });
                }, 100);
              });
            } else {
              onFetchCardNo(this.addForm.getFieldValue("accountNumber")).then(
                resp => {
                  if (resp.data.code === "SUCCESS") {
                    if (!resp.data.data.bankName) {
                      return;
                    }
                    const data = resp.data.data.bankName;
                    const bankCode = Number(resp.data.data.bankCode).toString();
                    this.bankNo = bankCode;
                    const bankName = data.split("\n")[0];
                    this.getSearchBank(bankName);
                    this.getSearchSubbranch("");
                    this.$nextTick(() => {
                      this.addForm.setFieldsValue({
                        bankNo: bankCode
                      });
                    });
                  }
                }
              );
            }
          } else {
            this.$message.error(resp.data.errMsg);
          }
        }
      );
    },
    // 输入银行卡号 失焦后触发
    checkCardNoDetail() {
      if (!this.editForm.getFieldValue("accountNumber")) {
        return;
      }
      onFetchCardNo(this.editForm.getFieldValue("accountNumber")).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (!resp.data.data.bankName) {
            return;
          }
          const data = resp.data.data.bankName;
          const bankCode = Number(resp.data.data.bankCode).toString();
          this.bankNo = bankCode;
          const bankName = data.split("\n")[0];
          this.getSearchBank(bankName);
          this.getSearchSubbranch("");
          this.$nextTick(() => {
            this.editForm.setFieldsValue({
              bankNo: bankCode
            });
          });
        }
      });
    },
    //确认
    handleOk() {
      if (this.isDetail) {
        this.addModel = false;
        return;
      }
      this.addForm.validateFields((error, value) => {
        if (!error) {
          let params = {
            cardType: value.cardType,
            accountNumber: value.accountNumber,
            provinceCode: value.cityCode[0],
            cityCode: value.cityCode[1],
            bankCode: value.bankNo,
            bankName: "",
            branchCode: value.branchNo,
            branchName: "",
            frontFile: "",
            backFile: ""
          };
          if (params.cardType == "DC") {
            params.frontFile = this.imgList[0].response.data;
            params.backFile = this.imgListBank[0].response.data;
          }
          this.backList.forEach(item => {
            if (item.bankNo == value.bankNo) {
              params.bankName = item.bankName;
            }
          });
          this.backChildList.forEach(item => {
            if (item.branchId == value.branchNo) {
              params.branchName = item.branchName;
            }
          });
          collectionCardAdd(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.addModel = false;
              this.$message.success("新增成功");
              this.pagination.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(resp.data.errMsg);
            }
          });
        }
      });
    },
    //取消
    handleCancel() {
      this.addModel = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onSet(item) {
      collectionCardSetDefault(item.bindId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(resp.data.errMsg);
        }
      });
    },
    //确认
    handleOkEdit() {
      this.editForm.validateFields((error, value) => {
        if (!error) {
          this.editModel = false;
          let params = {
            cardType: value.cardType,
            accountNumber: value.accountNumber,
            provinceCode: value.cityCode[0],
            cityCode: value.cityCode[1],
            bankCode: value.bankNo,
            bankName: "",
            branchCode: value.branchNo,
            branchName: "",
            frontFile: "",
            backFile: ""
          };
          if (params.cardType == "DC") {
            params.frontFile = this.imgList[0].fileUrl
              ? this.imgList[0].fileUrl
              : this.imgList[0].response.data;
            params.backFile = this.imgListBank[0].fileUrl
              ? this.imgListBank[0].fileUrl
              : this.imgListBank[0].response.data;
          }
          this.backList.forEach(item => {
            if (item.bankNo == value.bankNo) {
              params.bankName = item.bankName;
            }
          });
          this.backChildList.forEach(item => {
            if (item.branchId == value.branchNo) {
              params.branchName = item.branchName;
            }
          });
          collectionCardPut(this.bindId, params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.addModel = false;
              this.$message.success("修改成功");
              this.pagination.pageNum = 1;
              this.getList();
            } else {
              this.$message.error(resp.data.errMsg);
            }
          });
        }
      });
    },
    //取消
    handleCancelEdit() {
      this.editModel = false;
    },
    // 预览的图片
    async handlePreviewImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeImg({ fileList }) {
      this.imgList = fileList;
    },
    // 预览的反面图片
    async handlePreviewImageBank(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImageBank = file.url || file.preview;
      this.previewVisibleBank = true;
    },
    handleChangeImgBank({ fileList }) {
      this.imgListBank = fileList;
    },
    beforeUploadFile(file, fileListName) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("图片名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("图片大小超过20MB");
        } else {
          this.uploadFile("product_picture", file, fileListName);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
      }
    }
  }
};
</script>

<style scoped>
.table__container {
  margin: 24px;
}
</style>
