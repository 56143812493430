<template>
  <a-layout class="layout">
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>支付详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card-container">
      <div class="card-item-title">基本信息</div>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单号：
            </div>
            <div
              class="card-item-content"
              style="color: #3769ff;cursor: pointer"
              @click="orderDetail()"
            >
              {{ detailForm.relId }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              业务类型：
            </div>
            <div class="card-item-content">
              {{ detailForm.relTypeValue }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              付款机构：
            </div>
            <div class="card-item-content">
              {{ detailForm.paymentCorporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款机构：
            </div>
            <div class="card-item-content">
              {{ detailForm.accountCorporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款账户名称：
            </div>
            <div class="card-item-content">
              {{
                detailForm.accountUserRealName
                  ? detailForm.accountUserRealName
                  : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款银行名称：
            </div>
            <div class="card-item-content">
              {{ detailForm.accountBankName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款银行账号：
            </div>
            <div class="card-item-content">
              {{ detailForm.accountBankNumber }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款银行支行：
            </div>
            <div class="card-item-content">
              {{ detailForm.accountBankBranch }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              流程到达时间：
            </div>
            <div class="card-item-content">
              {{ detailForm.createTime ? toDate(detailForm.createTime) : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              待支付金额：
            </div>
            <div class="card-item-content">{{ detailForm.paymentNum }}元</div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container" v-if="type === 'detail'">
      <div class="card-item-title">支付信息</div>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付金额：
            </div>
            <div class="card-item-content">
              {{
                detailForm.accountNumber
                  ? detailForm.accountNumber
                  : detailForm.paymentNum
              }}元
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付时间：
            </div>
            <div class="card-item-content">
              {{ detailForm.submitTime ? toDate(detailForm.submitTime) : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              操作人：
            </div>
            <div class="card-item-content">
              {{ detailForm.paymentUserRealName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付流水号：
            </div>
            <div class="card-item-content">
              {{
                detailForm.bankRnList ? detailForm.bankRnList.join("、") : "--"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付状态：
            </div>
            <div class="card-item-content">
              {{
                detailForm.auditStatus === "un_submit" ||
                detailForm.auditStatus === "returned"
                  ? "未支付"
                  : "已支付"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title-content">
              支付凭证：
            </div>
            <div class="card-item-content" v-viewer>
              <img
                :data-source="imageBaseUrl + item.fileUrl"
                :src="imageBaseUrl + item.fileUrl"
                alt=""
                v-for="(item, index) in detailForm.evidenceFiles"
                :key="index"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item-title">流转信息</div>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="4" class="process__img">
          <a @click="onShowProcessImg" style="float: right">
            点击查看流程图
          </a>
          <div v-viewer class="images">
            <img
              src="../../assets/imgs/process/cashierDetail.png"
              :data-source="
                require('../../assets/imgs/process/cashierDetail.png')
              "
              alt="流程图"
            />
          </div>
        </a-col>
      </a-row>

      <a-table
        class="table"
        :columns="tblColumns"
        :data-source="tblData"
        row-key="index"
        :pagination="false"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? dtFormat(text) : "-" }}
        </template>
        <template slot="memo" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </div>
    <div class="card-container" v-if="type === 'pay'">
      <div class="card-item-title">支付信息</div>
      <a-form :form="form" @click="submit">
        <a-row>
          <a-col :span="8">
            <a-form-item label="支付金额">
              <a-input
                v-decorator="[
                  'accountNumber',
                  { rules: [{ required: true, message: `请输入支付金额` }] }
                ]"
                allow-clear
                addon-after="元"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付时间">
              <a-date-picker
                v-decorator="[
                  'paymentTime',
                  { rules: [{ required: true, message: `请选择支付时间` }] }
                ]"
                show-time
                type="date"
                placeholder="请选择"
                style="width: 100%;"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="银行流水号">
              <div class="numList">
                <div
                  class="input"
                  v-for="(item, index) in numList"
                  :key="index"
                >
                  <a-input v-model="item.num" placeholder="" />
                  <a-icon
                    type="close"
                    :style="{
                      fontSize: '16px',
                      color: '#FF5757',
                      marginLeft: '5px'
                    }"
                    @click="delItem(index)"
                  />
                </div>
                <div class="add" @click="addId">
                  <a-icon
                    type="plus"
                    :style="{
                      fontSize: '16px',
                      color: '#fff',
                      marginRight: '5px',
                      background: '#4368F6'
                    }"
                  />
                  增加收款流水
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="支付凭证" extra="图片大小限制在20MB以内">
              <a-upload
                v-decorator="['imgList']"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgVoucherUrl"
                :file-list="imgList"
                :before-upload="beforeUploadFile"
                @preview="handlePreviewImage"
                @change="handleChangeImg"
              >
                <div v-if="imgList.length < 8">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisible"
                @ok="previewVisible = false"
                @cancel="previewVisible = false"
              >
                <img
                  :src="previewImage"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="">
              <a-space>
                <a-button @click="back()" style="margin-left: 100px;">
                  取消
                </a-button>
                <a-button type="primary" @click="submit()">我已支付</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-layout>
</template>

<script>
import {
  logsList,
  ordersOfflinePaymentEdit,
  ordersOfflinePaymentGet
} from "@/services/OrderManageService";
import Moment from "moment";
import { dtFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import { imgVoucherUrl, upload } from "@/services/UploadService";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const tblColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: 160
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: 160
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: 160
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 160
  },
  {
    title: "执行动作",
    dataIndex: "operationValue",
    width: 160
  },
  {
    title: "审核意见",
    dataIndex: "memo",
    scopedSlots: { customRender: "memo" },
    width: 240
  }
];
export default {
  name: "CashierDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      imgVoucherUrl,
      imageBaseUrl,
      type: this.$route.query.type,
      tblColumns,
      tblData: [],
      detailForm: {},
      form: this.$form.createForm(this),
      numList: [],
      imgList: [],
      previewVisible: false,
      previewImage: "",
      dtFormat
    };
  },
  mounted() {
    ordersOfflinePaymentGet(this.$route.query.rn).then(resp => {
      this.detailForm = resp.data.data ? resp.data.data : {};
    });
    logsList({
      type: "offline_payment",
      relId: this.$route.query.rn
    }).then(resp => {
      this.tblData = resp.data.data;
    });
  },
  methods: {
    //时间转化
    toDate(time) {
      return Moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    back() {
      this.$router.back();
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    addId() {
      this.numList.push({
        num: ""
      });
    },
    delItem(index) {
      this.numList.splice(index, 1);
    },
    beforeUploadFile(file) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("图片名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("图片大小超过20MB");
        } else {
          this.uploadImage("offline_payment_file", file);
        }
      }
      return false;
    },
    async uploadImage(type, file) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        this.imgList.push({
          uid: this.imgList.length,
          name: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
      }
    },
    // 预览的图片
    async handlePreviewImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeImg({ fileList }) {
      this.imgList = fileList.filter(item => item.url);
    },
    submit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let params = {
            accountNumber: values.accountNumber,
            paymentTime: Moment(values.paymentTime),
            bankRnList: [],
            files: [],
            isSubmit: true,
            rn: this.$route.query.rn
          };
          this.numList.forEach(item => {
            if (item.num) {
              params.bankRnList.push(item.num);
            }
          });
          this.imgList.forEach(item => {
            params.files.push({
              fileUrl: item.response.data
            });
          });
          ordersOfflinePaymentEdit(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.info("操作成功");
              this.back();
            }
          });
        }
      });
    },
    orderDetail() {
      if (
        this.detailForm.relType === "purchase_account" ||
        this.detailForm.relType === "purchase_teller"
      ) {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailOperation",
          params: {
            orderSn: this.detailForm.relId,
            type: "detail",
            status: "payment-approval"
          }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "OperationReturnedOrderDetail",
          params: {
            orderSn: this.detailForm.relId,
            type: "detail",
            status: "payment-approval"
          }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
.layout {
  padding-bottom: 100px;
}
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.card-item {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  line-height: 32px;
}

.card-item-title {
  font-weight: 600;
  margin-bottom: 20px;
}
.card-item-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.card-item-content img {
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.images img {
  display: none;
}
/deep/ .ant-form-item-control {
  width: 90%;
}
.ant-form-item {
  display: flex;
}

.numList .input {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.numList .add {
  display: flex;
  align-items: center;
  color: #4368f6;
  cursor: pointer;
}
</style>
