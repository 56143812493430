<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>账号管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container">
      <section class="search__container">
        <common-search :fields="fields" @search="onSearch"></common-search>
      </section>
      <section class="table__container">
        <a-space>
          <a-button type="primary" icon="plus" @click="goAddUser">
            新建
          </a-button>
        </a-space>
        <a-table
          :columns="tblColumns"
          :data-source="tblData"
          :pagination="pagination"
          :loading="loading"
          class="data-table"
          rowKey="userId"
          @change="onTableChange"
        >
          <template slot="lockedFlag" slot-scope="record">
            <div>{{ record.lockedFlag ? "锁定" : "正常" }}</div>
          </template>

          <a-space slot="action" slot-scope="record">
            <a @click="onEdit(record)">编辑</a>
            <a-popconfirm
              title="确认删除角色吗?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </section>
    </div>
  </a-layout>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { getUserList, deleteUser } from "@/services/ValueBag";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "登录名",
    dataIndex: "userName",
    width: "10%"
  },
  {
    title: "姓名",
    dataIndex: "realName",
    width: "10%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    width: "15%"
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    width: "15%"
  },
  {
    title: "角色",
    dataIndex: "roles",
    width: "20%"
  },
  {
    title: "状态",
    key: "lockedFlag",
    scopedSlots: { customRender: "lockedFlag" },
    width: "5%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "登录名",
          key: "userName",
          component: "j-input"
        },
        {
          label: "姓名",
          key: "realName",
          component: "j-input"
        }
      ],
      tblColumns,
      tblData: [],
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 10
      },
      loading: false,
      form: {}
    };
  },
  activated() {
    this.loadUserList();
  },
  mounted() {
    this.loadUserList();
  },
  methods: {
    // 分页触发
    onTableChange(p) {
      this.pagination.current = p.current;
      this.loadUserList();
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadUserList();
    },
    // 新增账号
    goAddUser() {
      this.$router.push({ name: "addOrEditUserList" });
    },
    // 修改账号
    onEdit(record) {
      this.$router.push({
        name: "addOrEditUserList",
        query: { id: record.personnelId }
      });
    },
    // 获取表格数据
    loadUserList() {
      this.loading = true;
      let params = {
        realName: this.form.realName || "",
        userName: this.form.userName || "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      getUserList(params)
        .then(resp => {
          this.loading = false;
          this.tblData = resp.data.data.records;
          this.tblData.forEach(item => {
            if (item.createTime) {
              item.createTime = tsFormat(item.createTime);
            } else {
              item.createTime = "--";
            }
          });
          this.pagination.total = resp.data.data.total;
          this.pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 删除角色
    async onDelete(record) {
      await deleteUser(record.personnelId);
      this.$message.success("删除成功");
      this.loadUserList();
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item-required::before {
  display: none;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}
</style>
