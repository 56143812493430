<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <section>
        <div class="main-content">
          <a-table :data-source="tblData" :columns="tblColumns" :loading="loading" :pagination="pagination" rowKey="id"
            @change="onPage">
            <template slot="fileName" slot-scope="text, record">
              <a-space>
                <a @click="onDownLoadFile(record)">{{ record.fileName || "--" }}</a>
              </a-space>
            </template>
            <template slot="matchStatusName" slot-scope="text, record">
              <span :style="{
                color:
                  record.matchStatus === 2
                    ? 'rgba(255, 134, 0, 1)'
                    : ''
              }">
                {{ record.matchStatusName }}
              </span>
            </template>
            <template slot="createTime" slot-scope="text, record">
              {{ record.createTime ? tsFormat(record.createTime) : "--" }}
            </template>
            <template slot="action" slot-scope="text, record">
              <a-space>
                <a @click="onDetail(record)">查看详情</a>
              </a-space>
            </template>
          </a-table>
        </div>
      </section>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { orderAgriculturalList } from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "银行对账文件",
    dataIndex: "fileName",
    scopedSlots: { customRender: "fileName" },
    width: "50%"
  },
  {
    title: "支付通道",
    dataIndex: "paymentChannel",
    width: "10%"
  },
  {
    title: "对账结果",
    dataIndex: "matchStatusName",
    scopedSlots: { customRender: "matchStatusName" },
    width: "10%"
  },
  {
    title: "文件获取时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "AgriculturalBankList",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "文件获取时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "对账结果",
          key: "matchStatus",
          component: "j-select",
          defaultValue: "-1",
          options: [
            {
              label: "全部",
              value: "-1"
            },
            {
              label: "正常",
              value: "1"
            },
            {
              label: "异常",
              value: "2"
            }
          ]
        }
      ],
      form: {},
      pagination: {
        showQuickJumper: true
      },
      loading: false,
      tblColumns,
      tblData: [],
      tsFormat
    };
  },
  activated() {
    this.loadList();
  },
  mounted() { },
  methods: {
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      let params = {
        matchStatus: this.form.matchStatus || -1,
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        receiveCorporationId: localStorage.getItem("corporationId")
      };
      orderAgriculturalList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records.map(item => {
            item.paymentChannel = "农业银行";
            item.matchStatusName = item.matchStatus === 1 ? "正常" : "异常";
            return item;
          });
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          this.pagination = pagination;
        }
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 文件下载
    onDownLoadFile(item) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = item.filePath;
      link.setAttribute("download", decodeURI(item.fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    //详情
    onDetail(item) {
      this.$router.push({
        name: "AgriculturalDetail",
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  background-color: white;
  margin: 30px;
}
</style>
