var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("配置管理")]),_c('a-breadcrumb-item',[_vm._v("角色管理")])],1)],1),_c('div',{staticClass:"container"},[_c('section',{staticClass:"search__container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.search}})],1),_c('section',{staticClass:"table__container"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","icon":"plus"},on:{"click":_vm.showModal}},[_vm._v(" 新增 ")])],1),_c('a-table',{staticClass:"data-table",attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"pagination":_vm.pagination,"rowKey":"sysRoleId"},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"action",fn:function(record){return _c('span',{},[_c('a-space',[_c('a',{on:{"click":function($event){return _vm.onManageAppPrivilige(record)}}},[_vm._v("app权限设置")]),_c('a',{on:{"click":function($event){return _vm.onManageWebPrivilige(record)}}},[_vm._v("web权限设置")]),(!record.sysDefinedInd)?_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("修改")]):_vm._e(),_c('a-popconfirm',{attrs:{"title":"确认删除角色吗?","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.onDelete(record)}}},[(!record.sysDefinedInd)?_c('a',{staticClass:"delete"},[_vm._v("删除")]):_vm._e()])],1)],1)}}])})],1),_c('a-modal',{attrs:{"title":_vm.modalTitle,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"okText":"确定","cancelText":"取消"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleOk}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"角色名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'roleName',
              {
                rules: [{ required: true, message: '请输入角色名称' }]
              }
            ]),expression:"[\n              'roleName',\n              {\n                rules: [{ required: true, message: '请输入角色名称' }]\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"机构类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'distributorTypes',
              { rules: [{ required: true, message: '请选择机构类型' }] }
            ]),expression:"[\n              'distributorTypes',\n              { rules: [{ required: true, message: '请选择机构类型' }] }\n            ]"}],attrs:{"placeholder":"请选择","disabled":_vm.editMode}},_vm._l((_vm.distributorType),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description']),expression:"['description']"}],attrs:{"maxlength":200}})],1)],1)],1),_c('a-modal',{attrs:{"title":"提示","visible":_vm.visibleConfirm,"confirm-loading":_vm.confirmLoading,"okText":"确定","cancelText":"取消"},on:{"ok":_vm.handleOkTip,"cancel":_vm.handleCancelTip}},[_c('div',{staticClass:"personnelList"},_vm._l((_vm.personnelList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_vm._v(" "+_vm._s(item.corporationName)+"公司有员工正在使用该角色 ")])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }