<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>收付款记录</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="main__container">
      <section class="search__container">
        <common-search :fields="fields" @search="search"></common-search>
      </section>
      <section class="table__container">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          rowKey="rn"
          class="data-table"
          @change="onTableChange"
        >
          <!--收付金额-->
          <template slot="paymentNum" slot-scope="record">
            <div
              class="hingLight"
              v-if="record.accountCorporationId === corporationId"
            >
              {{ record.paymentNum }}
            </div>
            <div v-else>
              {{ record.paymentNum }}
            </div>
          </template>
          <!--支付时间-->
          <template slot="submitTime" slot-scope="text, record">
            {{ record.submitTime ? dtFormat(record.submitTime) : "-" }}
          </template>
        </a-table>
      </section>
    </div>
  </a-layout>
</template>
<script>
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { getPaymentList } from "@/services/FinancialService";
import { dtFormat } from "@/components/DateUtils";
import { fetchCorporationInfoByKeyword } from "@/services/OrderManageService";
const columns = [
  {
    title: "支付流水号",
    dataIndex: "rn"
  },
  {
    title: "付款方",
    dataIndex: "paymentCorporationName"
  },
  {
    title: "收款方",
    dataIndex: "accountCorporationName"
  },
  {
    title: "业务类型",
    dataIndex: "relTypeValue"
  },
  {
    title: "收付金额(元)",
    key: "paymentNum",
    scopedSlots: { customRender: "paymentNum" }
  },
  {
    title: "支付时间",
    key: "submitTime",
    scopedSlots: { customRender: "submitTime" }
  }
];
export default {
  name: "ReceiptAndPayRecords",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      fields: [
        {
          label: "支付流水号",
          key: "rn",
          component: "j-input"
        },
        {
          label: "付款方",
          key: "paymentDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "收款方",
          key: "receiveDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporationCollection(value);
          }
        },
        {
          label: "业务类型",
          key: "relType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "采购订单",
              value: "purchase"
            },
            {
              label: "退货订单",
              value: "returned"
            }
          ]
        },
        {
          label: "支付时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {},
      corporationCollectionList: [],
      columns,
      relType: "", // 业务类型
      tableData: [],
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 10
      },
      corporationId: localStorage.getItem("corporationId")
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    // 搜索付款机构
    async onSearchCorporation(value) {
      const corporationList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationList.push({
            label: item.corporationName + '(' + item.distributorTypeValue + ')',
            value: item.distributorId
          });
        });
      }
      return corporationList;
    },
    // 改变收款机构搜索内容
    async onSearchCorporationCollection(value) {
      const corporationCollectionList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationCollectionList.push({
            label: item.corporationName + '(' + item.distributorTypeValue + ')',
            value: item.distributorId
          });
        });
      }
      return corporationCollectionList;
    },
    dtFormat,
    // 分页触发
    onTableChange(p) {
      this.pagination.current = p.current;
      this.getTableList();
    },
    // 获取表格数据
    async getTableList() {
      const params = {
        rn: this.form.rn ? this.form.rn : "",
        paymentDistributorId: this.form.paymentDistributorId
          ? this.form.paymentDistributorId
          : "",
          receiveDistributorId: this.form.receiveDistributorId
          ? this.form.receiveDistributorId
          : "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        isLookCurrent: true
      };
      if (this.form.relType) {
        params.relTypes =
          this.form.relType === "purchase"
            ? ["purchase_account", "purchase_teller"]
            : ["returned_account", "returned_teller"];
      } else {
        params.relTypes = [];
      }
      const { data } = await getPaymentList(params);
      this.tableData = data.data.records;
      this.pagination.total = data.data.total;
      this.pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
    },
    // 搜索
    search(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getTableList();
    }
  }
};
</script>

<style scoped>
.hingLight {
  color: orange;
}
</style>
