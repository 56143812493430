<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>
          银行对账文件详情
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <a-row class="button-container-second">
        <a-button type="primary" @click="onRefreshPay" :loading="refreshLoading">
          刷新支付结果
        </a-button>
      </a-row>
      <section>
        <div class="main-content">
          <a-table
            :data-source="tblData"
            :columns="tblColumns"
            :loading="loading"
            :pagination="pagination"
            rowKey="serial"
            @change="onPage"
          >
            <template slot="bankPayStatus" slot-scope="text, record">
              <span
                :style="{
                  color:
                    record.bankPayStatus === '失败'
                      ? 'rgba(255, 134, 0, 1)'
                      : ''
                }"
              >
                {{ record.bankPayStatus }}
              </span>
            </template>
            <template slot="sysPayStatus" slot-scope="text, record">
              <span
                :style="{
                  color:
                    record.sysPayStatus === '失败'
                      ? 'rgba(255, 134, 0, 1)'
                      : ''
                }"
              >
                {{ record.sysPayStatus }}
              </span>
            </template>
            <template slot="time" slot-scope="text, record">
              {{ record.time ? msFormat(record.time) : "--" }}
            </template>
            <template slot="preline" slot-scope="value">
              <span style="white-space: pre-line;">{{ value ? value : "--" }}</span>
            </template>
          </a-table>
        </div>
      </section>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import {
  fetchCorporationInfoByKeyword,
  orderPaymentSync,
  orderAgriculturalInfo
} from "@/services/OrderManageService";
import { msFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "银行交易流水号",
    dataIndex: "serial",
    width: "15%"
  },
  {
    title: "付款账号和机构名称",
    dataIndex: "payMsg",
    scopedSlots: { customRender: "preline" },
    width: "15%"
  },
  {
    title: "收款账号和机构名称",
    dataIndex: "receiveMsg",
    scopedSlots: { customRender: "preline" },
    width: "15%"
  },
  {
    title: "交易金额（元）",
    dataIndex: "amount",
    width: "10%"
  },
  {
    title: "支付时间",
    dataIndex: "time",
    scopedSlots: { customRender: "time" },
    width: "15%"
  },
  {
    title: "银行支付状态",
    dataIndex: "bankPayStatus",
    scopedSlots: { customRender: "bankPayStatus" },
    width: "8%"
  },
  {
    title: "系统支付状态",
    dataIndex: "sysPayStatus",
    scopedSlots: { customRender: "sysPayStatus" },
    width: "8%"
  },
  {
    title: "订单号",
    dataIndex: "orderNo",
    width: "15%"
  },
  {
    title: "系统支付流水号",
    dataIndex: "rn",
    fixed: "right",
    width: "15%"
  }
];
export default {
  name: "AgriculturalDetail",
  components: { Breadcrumb, BreadcrumbItem },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "开始时间",
          key: "startTime",
          component: "j-time-picker"
        },
        {
          label: "结束时间",
          key: "endTime",
          component: "j-time-picker"
        },
        {
          label: "银行交易流水号",
          key: "serial",
          component: "j-input"
        },
        {
          label: "订单号",
          key: "orderNo",
          component: "j-input"
        },
        {
          label: "付款机构",
          key: "paymentCorporationCode",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        }
      ],
      form: {},
      pagination: {
        showQuickJumper: true
      },
      loading: false,
      refreshLoading: false,
      tblColumns,
      tblData: [],
      msFormat
    };
  },
  activated() {},
  mounted() {
    this.loadList();
  },
  methods: {
    // 搜索下单机构
    async onSearchCorporation(value) {
      const corporationList = [];
      if (value.trim()) {
        const resp = await fetchCorporationInfoByKeyword(value);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.forEach(item => {
            corporationList.push({
              label: item.corporationName + '(' + item.distributorTypeValue + ')',
              value: item.distributorId
            });
          });
        }
      }
      return corporationList;
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 刷新支付结果
    onRefreshPay() {
      this.refreshLoading = true;
      orderPaymentSync({settleId: this.$route.query.id}).then(resp => {
        this.refreshLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.loadList();
        }
      });
    },
    loadList() {
      if (this.form.startTime && this.form.endTime) {
        const sTime = new Date(this.form.startTime.format("YYYY-MM-DD HH:mm:ss")).getTime();
        const eTime = new Date(this.form.endTime.format("YYYY-MM-DD HH:mm:ss")).getTime();
        if (sTime > eTime) {
          this.$message.info("开始时间不能大于结束时间");
          return;
        }
      }
      this.loading = true;
      let startTime = "";
      let endTime = "";
      if (this.form.startTime) {
        startTime = this.form.startTime.format("HH:mm").split(":").join("")+"00";
      }
      if (this.form.endTime) {
        endTime = this.form.endTime.format("HH:mm").split(":").join("")+"59";
      }
      let params = {
        serial: ( this.form.serial || ""),
        orderNo: ( this.form.orderNo || ""),
        paymentCorporationCode: ( this.form.paymentCorporationCode || ""),
        settleId: this.$route.query.id,
        startTime,
        endTime,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        receiveCorporationId: localStorage.getItem("corporationId")
      };
      orderAgriculturalInfo(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records.map(item => {
            item.payMsg = (item.paymentAccount || "")  + "\n" + (item.paymentCorporationName || "");
            item.receiveMsg = (item.receiveAccount || "") + "\n" + (item.receiveCorporationName || "");
            return item;
          });
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          this.pagination = pagination;
        }
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    }
  }
};
</script>

<style scoped>
.content-container {
  background-color: white;
  margin: 30px;
}
.main-content {
  margin-top: 24px;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 110px;
}
/deep/ .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-form-item-children .ant-time-picker{
  width: 100%;
}
</style>
