<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>到账审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <section>
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :loading="loading"
          :pagination="pagination"
          rowKey="orderSn"
          @change="onPage"
        >
          <template slot="relId" slot-scope="text, record">
            <span>{{ record.relId ? record.relId : "--" }}</span>
          </template>
          <template slot="auditStatusValue" slot-scope="text, record">
            <span
              :style="{
                color:
                  record.auditStatusValue === '待审核'
                    ? 'rgba(255, 134, 0, 1)'
                    : ''
              }"
            >
              {{ record.auditStatusValue }}
            </span>
          </template>
          <template slot="createTime" slot-scope="text, record">
            {{ record.createTime ? dtFormat(record.createTime) : "--" }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a-space>
              <a
                @click="onExamine(record)"
                v-if="record.auditStatusValue === '待审核'"
              >
                审核
              </a>
              <a @click="onDetail(record)" v-else>查看</a>
            </a-space>
          </template>
        </a-table>
      </section>
    </div>
  </a-layout>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import {
  fetchCorporationInfoByKeyword,
  ordersOfflinePayment
} from "@/services/OrderManageService";
import { dtFormat } from "@/components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "支付流水号",
    dataIndex: "rn",
    width: "15%"
  },
  {
    title: "订单编号",
    dataIndex: "relId",
    scopedSlots: { customRender: "relId" },
    width: "15%"
  },
  {
    title: "付款机构",
    dataIndex: "paymentCorporationName",
    width: "15%"
  },
  {
    title: "支付金额（元）",
    dataIndex: "paymentNum",
    width: "10%"
  },
  {
    title: "到账金额（元）",
    dataIndex: "accountNumber",
    width: "10%"
  },
  {
    title: "流程状态",
    dataIndex: "auditStatusValue",
    scopedSlots: { customRender: "auditStatusValue" },
    width: "10%"
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "AccountList",
  components: { BreadcrumbItem, Breadcrumb },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "支付流水号",
          key: "rn",
          component: "j-input"
        },
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "流程状态",
          key: "auditStatus",
          component: "j-select",
          defaultValue: "to_approval",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待审核",
              value: "to_approval"
            },
            {
              label: "已退回",
              value: "returned"
            },
            {
              label: "已通过",
              value: "passed"
            }
          ]
        },
        {
          label: "付款机构",
          key: "paymentDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "提交时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "到款状态",
          key: "isPayment",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "已到款",
              value: "true"
            },
            {
              label: "未到款",
              value: "false"
            }
          ]
        }
      ],
      form: {
        auditStatus: "to_approval"
      },
      pagination: {
        showQuickJumper: true
      },
      loading: false,
      tblColumns,
      tblData: [],
      dtFormat
    };
  },
  activated() {
    this.loadList();
  },
  mounted() {},
  methods: {
    // 搜索下单机构
    async onSearchCorporation(value) {
      const corporationList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationList.push({
            label: `${item.corporationName}(${item.distributorTypeValue})`,
            value: item.distributorId
          });
        });
      }
      return corporationList;
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    loadList() {
      let params = {
        rn: this.form.rn || "",
        orderSn: this.form.orderSn || "",
        auditStatus: this.form.auditStatus || "",
        paymentDistributorId: this.form.paymentDistributorId || "",
        isPayment: this.form.isPayment || null,
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        receiveDistributorId: localStorage.getItem("distributorId")
      };
      ordersOfflinePayment(params).then(resp => {
        this.tblData = resp.data.data.records;
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    //审核
    onExamine(item) {
      this.$router.push({
        name: "AccountDetail",
        query: {
          type: "examine",
          rn: item.rn,
          relId: item.relId
        }
      });
    },
    //详情
    onDetail(item) {
      this.$router.push({
        name: "AccountDetail",
        query: {
          type: "detail",
          rn: item.rn,
          relId: item.relId
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  background-color: white;
  margin: 30px;
}
</style>
