<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>出纳支付</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <a-row class="button-container-second">
        <a-space>
          <a-button type="primary" @click="exports()" :loading="exportLoading">
            批量导出
          </a-button>
          <div class="import">
            批量导入出纳结果
            <input
              id="organization"
              ref="photo"
              type="file"
              multiple="multiple"
              accept=".xlsx"
              @change="change()"
            />
          </div>
        </a-space>
      </a-row>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="rn"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: record => ({
              props: {
                disabled: record.paymentStatus !== '未支付'
              }
            })
          }"
          @change="onPage"
        >
          <template slot="auditStatus" slot-scope="text, record">
            {{ record.paymentStatus }}
          </template>
          <template slot="createTime" slot-scope="text, record">
            {{ record.createTime ? dtFormat(record.createTime) : "--" }}
          </template>
          <template slot="confirmTime" slot-scope="text, record">
            {{ record.confirmTime ? dtFormat(record.confirmTime) : "--" }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a-space>
              <a
                @click="onPay(record)"
                v-if="record.paymentStatus === '未支付'"
              >
                支付
              </a>
              <a @click="onDetail(record)" v-else>查看</a>
            </a-space>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      title="导入提示"
      width="1200px"
      :visible="errModel"
      okText="确定"
      cancelText="取消"
      @ok="errModel = false"
      @cancel="errModel = false"
    >
      <a-table
        :data-source="errList"
        :columns="errColumns"
        :pagination="false"
      ></a-table>
    </a-modal>
  </a-layout>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import {
  fetchCorporationInfoByKeyword,
  offlinePaymentImport,
  ordersExport,
  ordersOfflinePayment
} from "@/services/OrderManageService";
import { baseURL } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { dtFormat } from "@/components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "支付流水号",
    dataIndex: "rn",
    width: "15%"
  },
  {
    title: "订单编号",
    dataIndex: "relId",
    width: "15%"
  },
  {
    title: "收款机构",
    dataIndex: "accountCorporationName",
    width: "10%"
  },
  {
    title: "待支付金额（元）",
    dataIndex: "paymentNum",
    width: "10%"
  },
  {
    title: "流程到达时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "支付状态",
    dataIndex: "auditStatus",
    scopedSlots: { customRender: "auditStatus" },
    width: "10%"
  },
  {
    title: "支付时间",
    dataIndex: "confirmTime",
    scopedSlots: { customRender: "confirmTime" },
    width: "15%"
  },
  {
    title: "操作人",
    dataIndex: "paymentUserRealName",
    width: "10%"
  },
  {
    title: "操作",
    key: "action",
    width: "10%",
    scopedSlots: { customRender: "action" }
  }
];
const errColumns = [
  {
    title: "行数",
    dataIndex: "lineNum",
    width: "15%"
  },
  {
    title: "错误原因",
    dataIndex: "errMsg",
    width: "15%"
  }
];
export default {
  name: "CashierList",
  components: { BreadcrumbItem, Breadcrumb },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "支付流水号",
          key: "rn",
          component: "j-input"
        },
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "支付状态",
          key: "auditStatusList",
          component: "j-select",
          defaultValue: "un_submit",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "未支付",
              value: "un_submit"
            },
            {
              label: "已支付",
              value: "to_approval"
            }
          ]
        },
        {
          label: "业务类型",
          key: "relType",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "采购订单",
              value: "account"
            },
            {
              label: "退款订单",
              value: "teller"
            }
          ]
        },
        {
          label: "收款机构",
          key: "receiveDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "流程到达时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        },
        {
          label: "提交时间",
          key: "createStatTime,createEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {
        auditStatusList: "un_submit"
      },
      pagination: {
        showQuickJumper: true
      },
      loading: false,
      importUrl: `${baseURL}/orders/offline-payment/import`,
      headers: buildHeaders(),
      tblColumns,
      selectedRowKeys: [],
      tblData: [],
      errModel: false,
      errList: [],
      errColumns,
      dtFormat,
      exportLoading: false
    };
  },
  activated() {
    this.loadList();
  },
  mounted() {
    // this.loadList();
  },
  methods: {
    // 搜索收款机构
    async onSearchCorporation(value) {
      const corporationList = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          corporationList.push({
            label: `${item.corporationName}(${item.distributorTypeValue})`,
            value: item.distributorId
          });
        });
      }
      return corporationList;
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 多选
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    loadList() {
      let params = {
        rn: this.form.rn || "",
        orderSn: this.form.orderSn || "",
        auditStatus: this.form.auditStatus || "",
        receiveDistributorId: this.form.receiveDistributorId || "",
        isPayment: this.form.isPayment || null,
        startTime: this.form.startTime || "",
        endTime: this.form.endTime || "",
        createStatTime: this.form.createStatTime || "",
        createEndTime: this.form.createEndTime || "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        paymentDistributorId: localStorage.getItem("distributorId"),
        relTypes:
          this.form.relType === "account"
            ? ["purchase_account", "returned_account"]
            : this.form.relType === "teller"
            ? ["purchase_teller", "returned_teller"]
            : "",
        isUnLookUnSubmit: false,
        auditStatusList:
          this.form.auditStatusList === "un_submit"
            ? "un_submit,returned"
            : this.form.auditStatusList === "to_approval"
            ? "to_approval,passed"
            : ""
      };
      ordersOfflinePayment(params).then(resp => {
        this.tblData = resp.data.data.records;
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    onDetail(item) {
      this.$router.push({
        name: "CashierDetail",
        query: {
          type: "detail",
          rn: item.rn,
          relId: item.relId
        }
      });
    },
    onPay(item) {
      this.$router.push({
        name: "CashierDetail",
        query: {
          type: "pay",
          rn: item.rn,
          relId: item.relId
        }
      });
    },
    exports() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.info("请选择");
        return;
      }
      this.exportLoading = true;
      let params = {
        rnList: this.selectedRowKeys
      };
      ordersExport(params).then(resp => {
        this.exportLoading = false;
        if (resp.status === 200) {
          this.selectedRowKeys = [];
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fURL = document.createElement("a");
          fURL.href = fileURL;
          fURL.setAttribute("download", "出纳支付.xlsx");
          document.body.appendChild(fURL);

          fURL.click();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    change() {
      const file = document.getElementById("organization").files[0];
      if (file) {
        document.getElementById("organization").value = "";
        this.loadPhotoData(file);
      }
    },
    loadPhotoData(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this;
      reader.onload = function() {
        const formData = new FormData();
        formData.append("file", file);
        offlinePaymentImport(formData).then(response => {
          if (response.data.code === "FAILED") {
            file.status = "failed";
            file.message = "上传失败";
            that.$message.error("导入失败");
            that.errModel = true;
            that.errList = response.data.data;
          } else {
            that.$message.success("导入成功");
            that.loadList();
          }
        });
      };
    }
  }
};
</script>

<style scoped>
.content-container {
  background-color: white;
  margin: 30px;
}
.main-content {
  margin-top: 24px;
}
.import {
  position: relative;
  padding: 0 16px;
  border: 1px solid #ededed;
  border-radius: 5px;
  line-height: 32px;
}
#organization {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}
</style>
