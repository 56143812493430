<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>角色管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="container">
      <section class="search__container">
        <common-search :fields="fields" @search="search"></common-search>
      </section>
      <section class="table__container">
        <a-space>
          <a-button type="primary" icon="plus" @click="showModal">
            新增
          </a-button>
        </a-space>
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          rowKey="sysRoleId"
          class="data-table"
          @change="onTableChange"
        >
          <span slot="action" slot-scope="record">
            <a-space>
              <a @click="onManageAppPrivilige(record)">app权限设置</a>
              <a @click="onManageWebPrivilige(record)">web权限设置</a>
              <a @click="onEdit(record)" v-if="!record.sysDefinedInd">修改</a>
              <a-popconfirm
                title="确认删除角色吗?"
                ok-text="确认"
                cancel-text="取消"
                @confirm="onDelete(record)"
              >
                <a v-if="!record.sysDefinedInd" class="delete">删除</a>
              </a-popconfirm>
            </a-space>
          </span>
        </a-table>
      </section>
      <a-modal
        :title="modalTitle"
        :visible="visible"
        :confirm-loading="confirmLoading"
        okText="确定"
        cancelText="取消"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <a-form :form="form" @submit="handleOk">
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="角色名称"
          >
            <a-input
              v-decorator="[
                'roleName',
                {
                  rules: [{ required: true, message: '请输入角色名称' }]
                }
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="机构类型"
          >
            <a-select
              placeholder="请选择"
              :disabled="editMode"
              v-decorator="[
                'distributorTypes',
                { rules: [{ required: true, message: '请选择机构类型' }] }
              ]"
            >
              <a-select-option
                v-for="item in distributorType"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            label="描述"
          >
            <a-textarea v-decorator="['description']" :maxlength="200" />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        title="提示"
        :visible="visibleConfirm"
        :confirm-loading="confirmLoading"
        okText="确定"
        cancelText="取消"
        @ok="handleOkTip"
        @cancel="handleCancelTip"
      >
        <div class="personnelList">
          <div class="item" v-for="(item, index) in personnelList" :key="index">
            {{ item.corporationName }}公司有员工正在使用该角色
          </div>
        </div>
      </a-modal>
    </div>
  </a-layout>
</template>
<script>
import {
  getRoleList,
  addRole,
  editRole,
  deleteRole,
  usePersonnel
} from "@/services/ValueBag";
import { pageDetailMixins } from "@/components/pageDetailMixin";
const columns = [
  {
    title: "角色名称",
    dataIndex: "roleName"
  },
  {
    title: "机构类型",
    dataIndex: "distributorTypes"
  },
  {
    title: "描述",
    dataIndex: "description"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};

export default {
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "角色名称",
          key: "roleName",
          component: "j-input"
        }
      ],
      tableData: [],
      columns,
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 10
      },
      visible: false, // 新增/编辑 弹窗
      confirmLoading: false,
      searchForm: {}, // 搜索表单
      form: this.$form.createForm(this), // 新增 修改表单
      formItemLayout,
      editMode: false, // 新增/修改
      roleId: "",
      distributorType: [
        { id: "warehouse", name: "货栈" },
        { id: "operationCenter", name: "运营中心" },
        { id: "operationHeadquarters", name: "运营总部" },
        { id: "serviceCenter", name: "服务中心" },
        { id: "endConsumerMarket", name: "渠道终端" },
        { id: "factory", name: "原厂库" }
      ],
      visibleConfirm: false,
      sysRoleId: "",
      personnelList: []
    };
  },
  computed: {
    modalTitle() {
      if (this.editMode === false) {
        return "新增角色";
      } else {
        return "修改角色名称";
      }
    }
  },
  mounted() {
    // 获取表格信息
    this.getTableList();
  },
  methods: {
    handleCancelTip() {
      this.visibleConfirm = false;
    },
    async handleOkTip() {
      console.log(this.sysRoleId);
      const { data } = await deleteRole(this.sysRoleId);
      if (data.code === "SUCCESS") {
        this.visibleConfirm = false;
        this.$message.success("删除成功");
        this.getTableList();
      }
    },
    // 分页触发
    onTableChange(p) {
      this.pagination.current = p.current;
      this.getTableList();
    },
    // 修改角色
    onEdit(record) {
      this.editMode = true;
      this.visible = true;
      this.roleId = record.sysRoleId;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          roleName: record.roleName,
          description: record.description,
          distributorTypes: this.distributorType.find(
            item => item.name === record.distributorTypes
          ).id
        });
      });
    },
    // 展示 修改/新增 弹窗
    showModal() {
      this.editMode = false;
      this.visible = true;
    },
    // 弹窗关闭
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    //删除角色
    onDelete(record) {
      this.confirmLoading = true;
      this.sysRoleId = record.sysRoleId;
      usePersonnel(record.sysRoleId).then(resp => {
        this.confirmLoading = false;
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data.length > 0) {
            this.visibleConfirm = true;
            this.personnelList = this.uniqueFunc(
              resp.data.data,
              "corporationId"
            );
          } else {
            this.handleOkTip();
          }
        }
      });
    },
    uniqueFunc(arr, uniId) {
      const res = new Map();
      return arr.filter(
        item => !res.has(item[uniId]) && res.set(item[uniId], 1)
      );
    },
    // 获取表格数据
    async getTableList() {
      let params = {
        roleName: this.searchForm.roleName || "",
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getRoleList(params);
      this.tableData = data.data.records;
      this.pagination.total = data.data.total;
      this.pagination.showTotal = function(total, range) {
        return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
      };
    },
    // 搜索
    search(form) {
      this.searchForm = form;
      this.pagination.current = 1;
      this.getTableList();
    },
    //管理web端权限
    onManageWebPrivilige(record) {
      this.$router.push({
        name: "setPrivilege",
        query: { id: record.sysRoleId, type: "web", name: record.roleName }
      });
    },
    // 管理app端权限
    onManageAppPrivilige(record) {
      this.$router.push({
        name: "setPrivilege",
        query: { id: record.sysRoleId, type: "app", name: record.roleName }
      });
    },
    // 提交表单
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;
          if (this.editMode) {
            // 编辑角色
            this.$message.success("修改成功");
            await editRole({ ...values, roleId: this.roleId });
          } else {
            //新增角色
            await addRole({ ...values });
            this.$message.success("新增成功");
          }
          setTimeout(() => {
            this.confirmLoading = false;
            this.getTableList();
            this.handleCancel();
          }, 500);
        }
      });
    }
  }
};
</script>

<style scoped>
.advanced-search-form /deep/ .ant-form-item {
  margin: 0;
  width: 90%;
}
.btn-search,
.btn-reset {
  margin-left: 2rem;
}

/deep/ .ant-table-tbody td {
  word-break: break-all;
}
.personnelList .item {
  margin-top: 5px;
}
</style>
