<template>
  <a-layout class="layout">
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>审核详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card-container">
      <div class="card-item-title">基本信息</div>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单号：
            </div>
            <div
              class="card-item-content"
              style="color: #3769ff;cursor: pointer"
              @click="orderDetail()"
              v-if="form.relTypeValue !== '购买物品物权'"
            >
              {{ form.relId }}
            </div>
            <div class="card-item-content" v-else>
              --
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付流水号：
            </div>
            <div class="card-item-content">
              {{ form.rn }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              付款机构：
            </div>
            <div class="card-item-content">
              {{ form.paymentCorporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款机构：
            </div>
            <div class="card-item-content">
              {{ form.accountCorporationName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              业务类型：
            </div>
            <div class="card-item-content">
              {{ form.relTypeValue }}
            </div>
          </div>
        </a-col>
        <a-col :span="8" v-if="form.relTypeValue === '购买物品物权'">
          <div class="card-item">
            <div class="card-item-title-content">
              商品名称：
            </div>
            <div class="card-item-content">
              {{ form.leaseAssetName }}
            </div>
          </div>
        </a-col>
        <a-col :span="8" v-if="form.relTypeValue === '购买物品物权'">
          <div class="card-item">
            <div class="card-item-title-content">
              序列号：
            </div>
            <div class="card-item-content">
              {{ form.leaseAssetSn }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付时间：
            </div>
            <div class="card-item-content">
              {{ form.submitTime ? toDate(form.submitTime) : "--" }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              流程状态：
            </div>
            <div class="card-item-content">
              {{ form.auditStatusValue }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              到款状态：
            </div>
            <div class="card-item-content">
              {{ form.accountStatus }}
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              收款流水号：
            </div>
            <div class="card-item-content">
              {{ form.bankRnList ? form.bankRnList.join() : "--" }}
            </div>
            <!--            <div class="numList" v-else>-->
            <!--              <div-->
            <!--                class="input"-->
            <!--                v-for="(item, index) in bankRnList"-->
            <!--                :key="index"-->
            <!--              >-->
            <!--                <a-input v-model="item.num" placeholder="" />-->
            <!--                <a-icon-->
            <!--                  type="close"-->
            <!--                  :style="{-->
            <!--                    fontSize: '16px',-->
            <!--                    color: '#FF5757',-->
            <!--                    marginLeft: '5px'-->
            <!--                  }"-->
            <!--                  @click="delItem(index)"-->
            <!--                />-->
            <!--              </div>-->
            <!--              <div class="add" @click="addId">-->
            <!--                <a-icon-->
            <!--                  type="plus"-->
            <!--                  :style="{-->
            <!--                    fontSize: '16px',-->
            <!--                    color: '#fff',-->
            <!--                    marginRight: '5px',-->
            <!--                    background: '#4368F6'-->
            <!--                  }"-->
            <!--                />-->
            <!--                增加收款流水-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </a-col>
        <a-col :span="24">
          <div class="card-item">
            <div class="card-item-title-content">
              银行凭证：
            </div>
            <div class="card-item-content " v-viewer>
              <img
                :data-source="imageBaseUrl + item.fileUrl"
                :src="imageBaseUrl + item.fileUrl"
                alt=""
                v-for="(item, index) in form.evidenceFiles"
                :key="index"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <div class="card-item-title">到账金额</div>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              支付金额：
            </div>
            <div class="card-item-content">{{ form.paymentNum }}元</div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item" style="align-items: center">
            <div class="card-item-title-content">
              实际到账金额：
            </div>
            <div class="card-item-content" v-if="type === 'detail'">
              {{ form.accountNumber }}元
            </div>
            <div class="card-item-content" v-else>
              <a-input addon-after="元" v-model="form.accountNumber" />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item-title">流转信息</div>
        </a-col>
        <a-col :span="12"></a-col>
        <a-col :span="4" class="process__img">
          <a @click="onShowProcessImg" style="float: right">
            点击查看流程图
          </a>
          <div v-viewer class="images">
            <img
              src="../../assets/imgs/process/accountDetail.png"
              :data-source="
                require('../../assets/imgs/process/accountDetail.png')
              "
              alt="流程图"
            />
          </div>
        </a-col>
      </a-row>

      <a-table
        class="table"
        :columns="tblColumns"
        :data-source="tblData"
        row-key="index"
        :pagination="false"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? dtFormat(text) : "-" }}
        </template>
        <template slot="memo" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </div>
    <div class="examine-container" v-if="type == 'examine'">
      <div class="textarea">
        <div class="label">审核意见：</div>
        <div class="value">
          <a-textarea v-model="memo" :auto-size="{ minRows: 1 }" />
        </div>
      </div>
      <div class="button-container">
        <div class="tip">
          <a-icon type="info-circle" class="tip__icon" />
          审核不通过，审核意见必填；实际到账金额输入到和支付金额不同时，审核只能不通过
        </div>
        <div class="operation">
          <a-button
            type="white"
            class="button-item"
            @click="onReject"
            :loading="loading"
          >
            不通过
          </a-button>
          <a-button
            type="primary"
            class="button-item"
            @click="onPass"
            :loading="loading"
          >
            通过
          </a-button>
        </div>
      </div>
    </div>
  </a-layout>
</template>

<script>
import {
  logsList,
  ordersOfflinePaymentGet,
  ordersOfflinePaymentPut
} from "@/services/OrderManageService";
import Moment from "moment/moment";
import { dtFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const tblColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: 160
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: 160
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: 160
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 160
  },
  {
    title: "执行动作",
    dataIndex: "operationValue",
    width: 160
  },
  {
    title: "审核意见",
    dataIndex: "memo",
    scopedSlots: { customRender: "memo" },
    width: 240
  }
];
export default {
  name: "AccountDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      imageBaseUrl,
      type: this.$route.query.type,
      form: {},
      tblColumns,
      tblData: [],
      memo: "",
      bankRnList: [],
      dtFormat,
      loading: false
    };
  },
  mounted() {
    ordersOfflinePaymentGet(this.$route.query.rn).then(resp => {
      this.form = resp.data.data ? resp.data.data : {};
    });
    logsList({
      type: "offline_payment",
      relId: this.$route.query.rn
    }).then(resp => {
      this.tblData = resp.data.data;
    });
  },
  methods: {
    //时间转化
    toDate(time) {
      return Moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    addId() {
      this.bankRnList.push({
        num: ""
      });
    },
    delItem(index) {
      this.bankRnList.splice(index, 1);
    },
    // 不通过
    onReject() {
      if (
        !(
          this.form.accountNumber >= 0 ||
          this.form.accountNumber === "" ||
          this.form.accountNumber === undefined
        )
      ) {
        this.$message.info("到账金额应大于等于0");
        return;
      }
      if (!this.memo) {
        this.$message.info("请输入审核意见");
        return;
      }
      let params = {
        rn: this.$route.query.rn,
        accountNumber: this.form.accountNumber ? this.form.accountNumber : "",
        memo: this.memo ? this.memo : "",
        isAdopt: false,
        bankRnList: []
      };
      this.bankRnList.forEach(item => {
        params.bankRnList.push(item.num);
      });
      this.loading = true;
      ordersOfflinePaymentPut(params).then(resp => {
        this.loading = false;
        if (resp.data.code == "SUCCESS") {
          this.$message.info("操作成功");
          this.$router.back();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 通过
    onPass() {
      if (this.form.accountNumber != this.form.paymentNum) {
        this.$message.info("到账金额与支付金额不符");
        return;
      }
      let params = {
        rn: this.$route.query.rn,
        accountNumber: this.form.accountNumber ? this.form.accountNumber : "",
        memo: this.memo ? this.memo : "",
        isAdopt: true,
        bankRnList: []
      };
      this.bankRnList.forEach(item => {
        if (item.num) {
          params.bankRnList.push(item.num);
        }
      });
      this.loading = true;
      ordersOfflinePaymentPut(params).then(resp => {
        this.loading = false;
        if (resp.data.code == "SUCCESS") {
          this.$message.info("操作成功");
          this.$router.back();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    orderDetail() {
      if (
        this.form.relType === "purchase_account" ||
        this.form.relType === "purchase_teller"
      ) {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailOperation",
          params: {
            orderSn: this.form.relId,
            type: "detail",
            status: "payment-approval"
          }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "OperationReturnedOrderDetail",
          params: {
            orderSn: this.form.relId,
            type: "detail",
            status: "payment-approval"
          }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
.layout {
  padding-bottom: 100px;
}
.card-container {
  background-color: white;
  margin: 10px 20px;
  padding: 25px 25px 0 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.card-item {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  line-height: 32px;
}

.card-item-title {
  font-weight: 600;
  margin-bottom: 20px;
}
.card-item-content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.card-item-content img {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.images img {
  display: none;
}
.numList .input {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.numList .add {
  display: flex;
  align-items: center;
  color: #4368f6;
  cursor: pointer;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}
.tip {
  display: flex;
  color: #999999;
  font-size: 12px;
  align-items: center;
}

.tip__icon {
  margin-right: 6px;
}
.button-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 220px;
}
.textarea {
  padding-left: 220px;
  display: flex;
}
.textarea .value {
  width: 400px;
}
</style>
