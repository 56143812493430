var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v(" 银行对账文件详情 ")])],1)],1),_c('div',{staticClass:"content-container"},[_c('common-search',{attrs:{"fields":_vm.fields},on:{"search":_vm.onSearch}}),_c('a-row',{staticClass:"button-container-second"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.refreshLoading},on:{"click":_vm.onRefreshPay}},[_vm._v(" 刷新支付结果 ")])],1),_c('section',[_c('div',{staticClass:"main-content"},[_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"loading":_vm.loading,"pagination":_vm.pagination,"rowKey":"serial"},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"bankPayStatus",fn:function(text, record){return [_c('span',{style:({
                color:
                  record.bankPayStatus === '失败'
                    ? 'rgba(255, 134, 0, 1)'
                    : ''
              })},[_vm._v(" "+_vm._s(record.bankPayStatus)+" ")])]}},{key:"sysPayStatus",fn:function(text, record){return [_c('span',{style:({
                color:
                  record.sysPayStatus === '失败'
                    ? 'rgba(255, 134, 0, 1)'
                    : ''
              })},[_vm._v(" "+_vm._s(record.sysPayStatus)+" ")])]}},{key:"time",fn:function(text, record){return [_vm._v(" "+_vm._s(record.time ? _vm.msFormat(record.time) : "--")+" ")]}},{key:"preline",fn:function(value){return [_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(value ? value : "--"))])]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }