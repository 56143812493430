<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>账号管理</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <div class="container">
        <a-form :form="form" @submit="onSubmit">
          <a-row>
            <a-col :span="8">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="登录名"
              >
                <a-input
                  :disabled="!!userId"
                  v-decorator="[
                    'userName',
                    {
                      rules: [{ required: true, message: '请输入登录名' }]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="真实姓名"
              >
                <a-input
                  v-decorator="[
                    'realName',
                    {
                      rules: [{ required: true, message: '请输入真实姓名' }]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                placeholder="请输入手机号或固定电话"
                label="手机号码"
              >
                <a-input
                  v-decorator="[
                    'mobile',
                    {
                      rules: [{ validator: compareMobile }]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="用户角色"
              >
                <a-select
                  placeholder="请选择"
                  mode="multiple"
                  v-decorator="[
                    'roleIds',
                    { rules: [{ required: true, message: '请选择角色' }] }
                  ]"
                >
                  <a-select-option
                    v-for="role in roles"
                    :value="role.sysRoleId"
                    :key="role.sysRoleId"
                  >
                    {{ role.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item
                label="状态"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-radio-group
                  v-decorator="[
                    'lockedFlag',
                    {
                      initialValue: false,
                      rules: [{ required: true, message: '请选择状态' }]
                    }
                  ]"
                >
                  <a-radio :value="false">正常</a-radio>
                  <a-radio :value="true">锁定</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="{ span: 16, offset: 6 }"
              >
                <a-space size="large">
                  <a-button type="primary" @click="onSubmit">提交</a-button>
                  <a-button @click="showUserPage">取消</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
  </a-layout>
</template>
<script>
import {
  addUser,
  editUser,
  getAvailableRoles,
  getUserDetail
} from "@/services/ValueBag";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
export default {
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      formItemLayout,
      form: this.$form.createForm(this, { name: "UserForm" }),
      userId: this.$route.query.id || "",
      roles: [] // 用户可选择角色列表
    };
  },
  created() {
    // 获取用户角色列表
    this.loadRoles();
  },
  mounted() {
    // 编辑状态 获取账号详情
    if (this.userId) {
      this.onDetail();
    }
  },
  methods: {
    // 编辑  获取账户详情
    async onDetail() {
      const { data } = await getUserDetail(this.userId);
      this.form.setFieldsValue({
        userName: data.data.userName,
        realName: data.data.realName,
        mobile: data.data.mobile,
        roleIds: data.data.roleIds,
        lockedFlag: data.data.lockedFlag
      });
    },
    // 获取角色列表
    async loadRoles() {
      const { data } = await getAvailableRoles({
        distributorTypes: localStorage.getItem("distributorType")
      });
      this.roles = data.data.map(item => {
        return {
          sysRoleId: item.sysRoleId,
          roleName: item.roleName
        };
      });
    },
    // 提交
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.userId) {
            // 编辑
            const obj = {
              realName: values.realName,
              mobile: values.mobile,
              roleIds: values.roleIds,
              lockedFlag: values.lockedFlag
            };
            await editUser(this.userId, obj);
            this.$message.success("修改成功");
          } else {
            // 新增
            const result = await addUser({
              ...values,
              password: ""
            });
            if (result.data.code === "FAILED") {
              return;
            }
            this.$message.success("新增成功");
          }
          this.showUserPage();
        }
      });
    },
    // 取消
    showUserPage() {
      this.$router.go(-1);
    },
    // 验证手机号
    compareMobile(rule, value, callback) {
      const validatePhoneReg = /^((0\d{2,3}-\d{6,8})|(1[3456789]\d{9}))$/;
      const isCorrect = validatePhoneReg.test(value);
      if (!isCorrect && value) {
        callback("手机号码格式不正确");
      } else {
        callback();
      }
    }
  }
};
</script>
<style scoped>
.container {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 28px 32px;
  display: flex;
  flex-direction: column;
}
/deep/.ant-col-6 {
  width: auto;
  min-width: 25%;
}
.extra__col /deep/ .ant-col-16 {
  width: auto;
  flex: 1;
}
</style>
